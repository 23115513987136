.root {
    margin: 40px;
    background-color: white;
  }
  
  .label {
    text-align: center;
    font-weight: 600;
    font-size: 100px; /* rem(100) */
    line-height: 1;
    margin-bottom: calc(2.5rem * 1.5); /* theme.spacing.xl */
    color: #ff008a;
  }
  
  .title {
    font-family: "Greycliff CF", sans-serif; /* Assuming theme.fontFamily is a default sans-serif */
    text-align: center;
    font-weight: 800;
    font-size: 22px; /* rem(22) */
    color: #f752ab;
  }
  
  .description {
    max-width: 810px;
    margin: auto;
    font-size: 17px; /* rem(17) */
    margin-top: 2.5rem; /* theme.spacing.xl */
    margin-bottom: calc(2.5rem * 1.5); /* theme.spacing.xl */
    color: black;
  }
  
  .goBackBtn {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 20px; /* theme.fontSizes.xl */
    color: white;
    justify-content: center;
    background-color: #ff008a;
    margin-bottom: 0.2rem;
    height: 45px;
    width: 150px;
    padding: 8px; /* theme.spacing.sm */
    font-weight: 500;
    border-radius: 0.5rem;
  }
  
  .goBackBtn:hover {
    background-color: #ff008a;
    color: white;
  }
  