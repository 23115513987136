.mainBox {
  background-color: #ffe6f3;
  padding-top: 2rem;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin: 15rem;
  color: #ff008a;
  padding: 2rem;
}

.grid {
  margin-block: 5rem;
  display: flex;
  justify-content: space-around;
}

.card {
  margin: auto;
  justify-content: center;
  border-radius: 0;
  padding: 2rem;
}

.cardTitle {
  background-color: #ff008a;
  height: 3rem;
  border-radius: 0.5rem;
  align-content: center;
  color: white;
}

.qr {
  width: 256px;
  aspect-ratio: 1/1;
  height: 256px;
}

@media(max-width:400px) {
  .qr {
    width: 180px;
    height: 180px;
  }

  .card {
    padding: 1rem;
  }
}