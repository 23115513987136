.header {
  background-color: #ff008a;
  margin: 0;
  width: "100%";
  flex: 0
}

.logo {
  height: 40px;
}

.flexContainer {
  display: flex;
  margin-inline: 45px;
  padding-block: 12px;
  justify-content: space-between;
}


.mainPaper {
  margin: 0;
  width: "100%";
}

.profileMenu {
  color: green;
  font-size: 0.1rem;
  border-radius: 1rem;
}

.profileList {
  text-decoration: none;
}

.profileListItem {
  font-size: var(--mantine-font-size-xs);
  /* Theme variable */
  transition: all 0.3s ease-in-out 0s;
}

.profileListItem:hover {
  color: var(--mantine-color-violet-6);
  /* Theme variable */
  background-color: white;
}

@media(max-width:400px) {
  .flexContainer {
    margin-inline: 16px;
  }

}