.box {
  width: 80%;
  max-width: 25rem;
  margin: auto;
  margin-top: var(--mantine-spacing-xl);
}

.title {
  text-align: center;
  margin: 0;
  font-family: sans-serif;
}

.text {
  font-size: var(--mantine-font-size-sm);
  text-align: center;
  margin-top: var(--mantine-spacing-sm);
}

.logo {
  margin: auto;
  margin-top: 4rem;
  width: 75px;
}

.main {
  background-color: #ffffff;
}

.btn {
  background: #00882e;
  height: 2.5rem;
}

.btn:hover {
  background: #00882e;
  height: 2.5rem;
}

.errorMessage {
  color: red;
  text-align: center;
}